import 'monaco-editor';
import { editor } from 'monaco-editor/esm/vs/editor/editor.api';
import { Node, NodePositionChange, XYPosition } from 'react-flow-renderer';

type GetHelperLinesResult = {
  horizontal?: number;
  vertical?: number;
  snapPosition: Partial<XYPosition>;
};

interface ConfigTemplateProps {
  name?: string;
  description?: string;
  components?: any;
  connectors?: any;
}

export const createVisualTemplate = (data: ConfigTemplateProps) =>
  `category: null
description: ${data.name || 'Default component description'}
name: ${data.name || 'Component'}
flairs:
  input1:
    flow: INLET
    heading: LEFT
    placement:
      x: 0
      y: -15
    size: null
    type: portAnchor
  input2:
    flow: INLET
    heading: LEFT
    placement:
      x: 0
      y: 0
    size: null
    type: portAnchor
  input3:
    flow: INLET
    heading: LEFT
    placement:
      x: 0
      y: 15
    size: null
    type: portAnchor
  input4:
    flow: INLET
    heading: RIGHT
    placement:
      x: 0
      y: -15
    size: null
    type: portAnchor
  input5:
    flow: INLET
    heading: RIGHT
    placement:
      x: 0
      y: 0
    size: null
    type: portAnchor
  input6:
    flow: INLET
    heading: RIGHT
    placement:
      x: 0
      y: 15
    size: null
    type: portAnchor
  nameLabel:
    background_color: null
    default: Pixer
    placement:
      x: 19
      y: 23
    prefix: null
    size: null
    suffix: null
    text_color: null
    text_size: M
    type: label
  output1:
    flow: OUTLET
    heading: null
    placement:
      x: -25
      y: 70
    size: null
    type: portAnchor
  output2:
    flow: OUTLET
    heading: null
    placement:
      x: 25
      y: 70
    size: null
    type: portAnchor
size: 
  width: 72
  height: 72
`;

export const createConfigTemplate = (data: ConfigTemplateProps) =>
  `components:
  Buffer concentrate tote:
    instruments: {}
    ports:
      Feed:
        anchor: feed
    render:
    - -122
    - 2
    visual:
      $ref: '#/visuals/Buffer Concentrate tote'
      flairs:
        feed:
          placement:
            x: -46
            y: 107
          type: portAnchor
        fillBar: null
        fillPercentage: null
        nameLabel:
          backgroundColor: default
          default: Buffer Concentrate
          placement:
            x: 0
            y: 0
          textColor: default
          textSize: L
          type: label
        weight: null
      input:
        fillBar: $.stream.wit02.state.pv
        fillPercentage: $.stream.wit02.state.pv
        name: $.name
        weight: $.stream.wit02.state.pv
      size:
        height: 320
        width: 320
  Pixer:
    instruments:
      pump01:
        attributes:
          cmd:
            default:
              accel: 0
              decel: 0
              dir_cmd: false
              disable: 0
              enable: false
              fault_reset: false
              mode: 0
              run: 0
              sp: 0
              start: 0
              stop: false
            stateSchemaId: paracloud.ai/schemas/control-task-app-demo/attribute/state/PumpCmd
          state:
            default:
              accel: 0
              decel: 0
              dir_cmd: false
              disable: 0
              enable: false
              enabled: false
              fault_active: false
              fault_reset: false
              feedback_pv: 0
              mode: 0
              reference_sp: 0
              run: 0
              selected: true
              sp: 0
              start: 0
              stop: false
            stateSchemaId: paracloud.ai/schemas/control-task-app-demo/attribute/state/PumpInOutState
    ports:
      output1:
        anchor: output1
    render:
    - 3
    - 307
    visual:
      $ref: '#/visuals/Pixer'
      flairs:
        nameLabel:
          backgroundColor: default
          default: Pixer
          placement:
            x: 0
            y: 0
          textColor: default
          textSize: L
          type: label
        speed:
          backgroundColor: default
          default: '0.00'
          placement:
            x: 0
            y: 80
          suffix: RPM
          textColor: default
          textSize: L
          type: label
      input:
        speed: $.stream.pump01.state.reference_sp
  phase:
    componentType: hidden
    instruments:
      phase:
        attributes:
          cmd:
            default:
              abort: false
              hold: false
              load: false
              path: ''
              restart: false
              start: false
            stateSchemaId: paracloud.ai/schemas/control-task-app-demo/attribute/state/BatchCmd
          state:
            default:
              abort: false
              display: ''
              hold: false
              load: false
              path: ''
              restart: false
              start: false
              state: 0
            stateSchemaId: paracloud.ai/schemas/control-task-app-demo/attribute/state/BatchCmdState
    ports: {}
    render:
    - -218
    - 223
connectors:
  Pixer to Buffer concentrate tote:
    instruments: {}
    ports:
    - $ref: '#/components/Pixer/ports/output1'
    - $ref: '#/components/Buffer concentrate tote/ports/Feed'
${data?.name ? `name: ${data.name}` : 'name: New template'}
${
  data?.description
    ? `description: ${data.description}`
    : 'description: Default description'
}
visuals:
  Buffer Concentrate tote:
        flairs: {}
        id: VSUL203ECE993FE24D578C68237B611FB394
        version: 14
  Pixer:
        flairs: {}
        id: VSULAF29DA6930CC424CB868A8B31798AFAE
        version: 10`;

export const resetDecorations = (yamlInstance: editor.ICodeEditor): void => {
  yamlInstance.createDecorationsCollection([]);
};

// this utility function can be called with a position change (inside onNodesChange)
// it checks all other nodes and calculated the helper line positions and the position where the current node should snap to
export function getHelperLines(
  change: NodePositionChange,
  nodes: Node[],
  distance = 5,
): GetHelperLinesResult {
  const defaultResult = {
    horizontal: undefined,
    vertical: undefined,
    snapPosition: { x: undefined, y: undefined },
  };
  const nodeA = nodes.find((node) => node.id === change.id);

  if (!nodeA || !change.position) {
    return defaultResult;
  }

  const nodeABounds = {
    left: change.position.x,
    right: change.position.x + (nodeA.width ?? 0),
    top: change.position.y,
    bottom: change.position.y + (nodeA.height ?? 0),
    width: nodeA.width ?? 0,
    height: nodeA.height ?? 0,
  };

  let horizontalDistance = distance;
  let verticalDistance = distance;

  return nodes
    .filter((node) => node.id !== nodeA.id)
    .reduce<GetHelperLinesResult>((result, nodeB) => {
      const nodeBBounds = {
        left: nodeB.position.x,
        right: nodeB.position.x + (nodeB.width ?? 0),
        top: nodeB.position.y,
        bottom: nodeB.position.y + (nodeB.height ?? 0),
        width: nodeB.width ?? 0,
        height: nodeB.height ?? 0,
      };

      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |
      //  |___________|
      //  |
      //  |
      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     B     |
      //  |___________|
      const distanceLeftLeft = Math.abs(nodeABounds.left - nodeBBounds.left);

      if (distanceLeftLeft < verticalDistance) {
        result.snapPosition.x = nodeBBounds.left;
        result.vertical = nodeBBounds.left;
        verticalDistance = distanceLeftLeft;
      }

      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |
      //  |___________|
      //              |
      //              |
      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     B     |
      //  |___________|
      const distanceRightRight = Math.abs(
        nodeABounds.right - nodeBBounds.right,
      );

      if (distanceRightRight < verticalDistance) {
        result.snapPosition.x = nodeBBounds.right - nodeABounds.width;
        result.vertical = nodeBBounds.right;
        verticalDistance = distanceRightRight;
      }

      //              |‾‾‾‾‾‾‾‾‾‾‾|
      //              |     A     |
      //              |___________|
      //              |
      //              |
      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     B     |
      //  |___________|
      const distanceLeftRight = Math.abs(nodeABounds.left - nodeBBounds.right);

      if (distanceLeftRight < verticalDistance) {
        result.snapPosition.x = nodeBBounds.right;
        result.vertical = nodeBBounds.right;
        verticalDistance = distanceLeftRight;
      }

      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |
      //  |___________|
      //              |
      //              |
      //              |‾‾‾‾‾‾‾‾‾‾‾|
      //              |     B     |
      //              |___________|
      const distanceRightLeft = Math.abs(nodeABounds.right - nodeBBounds.left);

      if (distanceRightLeft < verticalDistance) {
        result.snapPosition.x = nodeBBounds.left - nodeABounds.width;
        result.vertical = nodeBBounds.left;
        verticalDistance = distanceRightLeft;
      }

      //  |‾‾‾‾‾‾‾‾‾‾‾|‾‾‾‾‾|‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |     |     B     |
      //  |___________|     |___________|
      const distanceTopTop = Math.abs(nodeABounds.top - nodeBBounds.top);

      if (distanceTopTop < horizontalDistance) {
        result.snapPosition.y = nodeBBounds.top;
        result.horizontal = nodeBBounds.top;
        horizontalDistance = distanceTopTop;
      }

      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |
      //  |___________|_________________
      //                    |           |
      //                    |     B     |
      //                    |___________|
      const distanceBottomTop = Math.abs(nodeABounds.bottom - nodeBBounds.top);

      if (distanceBottomTop < horizontalDistance) {
        result.snapPosition.y = nodeBBounds.top - nodeABounds.height;
        result.horizontal = nodeBBounds.top;
        horizontalDistance = distanceBottomTop;
      }

      //  |‾‾‾‾‾‾‾‾‾‾‾|     |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |     |     B     |
      //  |___________|_____|___________|
      const distanceBottomBottom = Math.abs(
        nodeABounds.bottom - nodeBBounds.bottom,
      );

      if (distanceBottomBottom < horizontalDistance) {
        result.snapPosition.y = nodeBBounds.bottom - nodeABounds.height;
        result.horizontal = nodeBBounds.bottom;
        horizontalDistance = distanceBottomBottom;
      }

      //                    |‾‾‾‾‾‾‾‾‾‾‾|
      //                    |     B     |
      //                    |           |
      //  |‾‾‾‾‾‾‾‾‾‾‾|‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾
      //  |     A     |
      //  |___________|
      const distanceTopBottom = Math.abs(nodeABounds.top - nodeBBounds.bottom);

      if (distanceTopBottom < horizontalDistance) {
        result.snapPosition.y = nodeBBounds.bottom;
        result.horizontal = nodeBBounds.bottom;
        horizontalDistance = distanceTopBottom;
      }

      return result;
    }, defaultResult);
}
