import { rootApi } from '..';
import { apiBaseUrlV1 } from '../../../env';
import { isFulfilled } from '../utils';
import {
  AggregateObject,
  ControlPackage,
  ControlPackageDetails,
  ControlTask,
  ControlTaskDeployment,
  ControlTaskDeploymentCreateCommand,
  ControlView,
  SoftwareType,
  TaskDeploymentParams,
  TaskDetails,
  TaskTargets,
  TasksQueryParams,
  PackageState,
  PackageAssets,
} from './types';

const BASE_URL = `${apiBaseUrlV1('control/v1/packages')}`;

export const extendedApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    uploadPackage: builder.mutation<
      ControlPackage,
      { formData: FormData; id: string }
    >({
      query: ({ formData, id }) => ({
        url: `${BASE_URL}?project=${id}`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['ControlPackage'],
    }),
    getPackages: builder.query<
      ControlPackage[],
      { id?: string; excludeArchived: boolean; deploymentDate?: string }
    >({
      query: ({ id, excludeArchived, deploymentDate }) => {
        const excludeArchivedLink = `?exclude_archived=${excludeArchived}`;
        const ifExcludeArchived = `${
          excludeArchived ? excludeArchivedLink : ''
        }`;

        return `${BASE_URL}${
          id
            ? `?project=${id}&exclude_archived=${excludeArchived}`
            : ifExcludeArchived
        }${deploymentDate ? `?deploymentDate=${deploymentDate}` : ''}`;
      },
      providesTags: ['ControlPackage'],
      transformResponse: (packages: ControlPackage[]) =>
        packages.map(
          (pkg) =>
            ({
              ...pkg,
              type: SoftwareType.ens,
            } as ControlPackage),
        ),
    }),
    updatePackage: builder.mutation<
      ControlPackage,
      { id: string; archived: boolean }
    >({
      query: ({ id, archived }) => {
        const idLink = `/${id}`;
        return {
          url: `${BASE_URL}${id ? idLink : ''}/archive`,
          method: 'PATCH',
          body: { archived },
        };
      },
      invalidatesTags: ['ControlPackage'],
    }),
    getPackageDetails: builder.query<ControlPackageDetails, string>({
      async queryFn(id, _queryApi, _extraOptions, fetchWithBQ) {
        if (id !== undefined) {
          const settled = await Promise.allSettled([
            fetchWithBQ(apiBaseUrlV1(`control/v1/packages/${id}`)),
            fetchWithBQ({
              url: apiBaseUrlV1('control/v1/tasks'),
              params: { package: id },
            }),
            fetchWithBQ({
              url: apiBaseUrlV1('control/v1/views'),
              params: { package: id },
            }),
          ]);
          const [packageResult, tasksResult, viewsResult] = settled
            .filter(isFulfilled)
            .map((result) => result.value);
          const firstError = [tasksResult, viewsResult].find((it) => it.error);
          if (firstError) {
            return { error: firstError.error };
          }
          return {
            data: {
              pkg: packageResult.data as ControlPackage,
              tasks: tasksResult.data as ControlTask[],
              views: viewsResult.data as ControlView[],
            } as ControlPackageDetails,
          };
        }
        return {
          data: {
            pkg: {} as ControlPackage,
            tasks: [] as ControlTask[],
            views: [] as ControlView[],
          } as ControlPackageDetails,
        };
      },
    }),
    getPackageState: builder.query<PackageState, string>({
      query: (id) => ({
        url: `${BASE_URL}/${id}/archiveable`,
      }),
    }),
    getPackageTargets: builder.query<TaskTargets, string>({
      query: (id) =>
        `${apiBaseUrlV1(
          'control/v1/views',
        )}?package=${id}&include_targets=True`,
    }),
    getTaskDeployments: builder.query<
      ControlTaskDeployment[],
      TaskDeploymentParams
    >({
      query: ({ task, target, deploymentDate }) => ({
        url: apiBaseUrlV1('control/v1/tasksDeployment'),
        params: {
          task: task || undefined,
          target: target || undefined,
          deploymentDate: deploymentDate || undefined,
        },
      }),
      providesTags: ['ControlTaskDeployment'],
      transformResponse: (data: ControlTaskDeployment[]) => {
        data.sort((a, b) => {
          if (Date.parse(a.modifiedAt) - Date.parse(b.modifiedAt) >= 1)
            return -1;
          if (Date.parse(a.modifiedAt) - Date.parse(b.modifiedAt) <= -1)
            return 1;
          return 0;
        });
        return data;
      },
    }),
    getTaskDetails: builder.query<any, string[]>({
      queryFn: async (ids, _queryApi, _extraOptions, fetchWithBQ) => {
        const promises = ids.map((id) =>
          fetchWithBQ({
            url: `${apiBaseUrlV1('control/v1/tasksDeployment')}`,
            params: { task: id },
          }),
        );
        const result = await Promise.all(promises).then((res) => ({
          data: res,
        }));

        return result as TaskDetails;
      },
    }),
    getTask: builder.query<ControlTask, TasksQueryParams>({
      query: ({ taskId, packageId }) => {
        const taskIdLink = `/${taskId}`;
        return {
          url: `${apiBaseUrlV1('control/v1/tasks')}${taskId ? taskIdLink : ''}`,
          params: { package: packageId },
        };
      },
      providesTags: ['ControlTask'],
    }),
    getTasks: builder.query<ControlTask[], { deploymentDate: string }>({
      query: (deploymentDate) => ({
        url: `${apiBaseUrlV1('control/v1/tasks')}${
          deploymentDate?.deploymentDate
            ? `?deploymentDate=${deploymentDate?.deploymentDate}`
            : ''
        }`,
      }),
      providesTags: ['ControlTasks'],
    }),
    getTaskAggregationDetails: builder.query<AggregateObject[], string>({
      query: (taskId) => ({
        url: `${apiBaseUrlV1('control/v1/aggregates')}${
          taskId ? `/${taskId}` : ''
        }/events`,
      }),
    }),
    createTaskDeployment: builder.mutation<
      ControlTaskDeployment,
      ControlTaskDeploymentCreateCommand
    >({
      query: (body) => ({
        url: apiBaseUrlV1('control/v1/tasksDeployment'),
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ControlTask', 'ControlTasks'],
    }),
    getPackageAssets: builder.query<PackageAssets, string>({
      query: (id) => ({
        url: apiBaseUrlV1(`control/v1/assets?name=${id}.tgz`),
        method: 'GET',
      }),
    }),
    downloadPackageAssets: builder.query<any, { id: string; name: string }>({
      queryFn: async ({ id, name }, _api, _extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: apiBaseUrlV1(`control/v1/assets/${id}/file`),
          responseHandler: (response) => response.blob(),
        });
        const hiddenElement = document.createElement('a');
        const url = window.URL || window.webkitURL;
        const blobPackage = url.createObjectURL(result.data as Blob);
        hiddenElement.href = blobPackage;
        hiddenElement.target = '_blank';
        hiddenElement.download = name;
        hiddenElement.click();
        return { data: null };
      },
    }),
  }),
});

export const {
  useGetPackagesQuery,
  useUpdatePackageMutation,
  useUploadPackageMutation,
  useGetPackageDetailsQuery,
  useGetPackageTargetsQuery,
  useGetTaskDeploymentsQuery,
  useGetTaskQuery,
  useGetTasksQuery,
  useGetTaskAggregationDetailsQuery,
  // useGetAggregationDetailsQuery,
  useGetTaskDetailsQuery,
  useCreateTaskDeploymentMutation,
  useGetPackageStateQuery,
  useLazyDownloadPackageAssetsQuery,
  useGetPackageAssetsQuery,
} = extendedApi;
