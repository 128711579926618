import React, { useEffect, useMemo, useState } from 'react';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { useLocation } from 'react-router';
import { matchPath } from 'react-router-dom';
import { AggregateType, deriveIDWithType } from 'pc-id';
import { useGetSystemStatusQuery } from '../redux/services/polling';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { extendedApi, useGetSystemsQuery } from '../redux/services/systems/api';
import { IAppState } from '../typescript/interfaces/appstate.interface';
import MachineSubscriptionComponent from './MachineSubscriptionComponent';
import { useGetInstrumentsByAggregateQuery } from '../redux/services/instruments/api';
import { useGetAttributesQuery } from '../redux/services/attributes';
import { apiBaseUrlV1 } from '../env';
import PhaseEventSourceComponent from './PhaseEventSourceComponent';
import { routes } from '../constants';
import { setControlSubscriptionStatusesLoaded } from '../redux/modules/view';
import { setUser } from '../redux/modules/user';
import { isUnauthorizedError } from '../redux/utils';
import { IResData } from '../redux/services/controllerBatch/api';

interface IMessage {
  data: string;
  target: EventSourcePolyfill;
  type: string;
  lastEventId: string;
}

const WithSubscriptionItem = ({
  system,
  handlePhaseComponent,
}: {
  system: string;
  handlePhaseComponent: ({
    id,
    phaseId,
  }: {
    id: string;
    phaseId: string;
  }) => void;
}) => {
  const { data: phase } = useGetInstrumentsByAggregateQuery(system, {
    selectFromResult: (res) => ({
      ...res,
      data: res?.data?.find(({ name }) => name === 'phase'),
    }),
  });

  const { data: attribute } = useGetAttributesQuery(
    { parentAggregate: phase?.id },
    {
      selectFromResult: (res) => ({
        ...res,
        data: res?.data?.find(({ name }) => name === 'state'),
      }),
      skip: !phase?.id,
    },
  );

  useEffect(() => {
    if (attribute) {
      handlePhaseComponent({
        id: system,
        phaseId: deriveIDWithType(attribute.id, AggregateType.CATR),
      });
    }
  }, [attribute]);

  return undefined;
};

const WithSubscriptionSummarizedComponent = ({
  systems,
}: {
  systems: string[];
}) => {
  const [message, setMessage] = useState<IMessage>();
  const dispatch = useAppDispatch();
  const controlStatusLoaded = useAppSelector(
    (state: IAppState) => state.view.controlStatusLoaded,
  );

  const { data: systemsSelector, isSuccess } = useGetSystemsQuery();

  const [systemsSummarized, setSystemsSummarized] = useState<
    { id: string; phaseId: string }[]
  >([]);

  useEffect(() => {
    if (systems.length > 0) {
      setSystemsSummarized((prev) => {
        if (prev.length === 0) return [];
        return prev.filter(({ id }) => systems.indexOf(id) > -1);
      });
    }
  }, [systems]);

  useEffect(() => {
    if (message && systemsSummarized?.length > 0) {
      if (!('data' in message)) return;

      const { id, state: stateMessage }: IResData = JSON.parse(message.data);
      const { display, state }: { display: string; state: number } = JSON.parse(
        JSON.parse(stateMessage),
      );

      if (!id) return;

      const systemId = systemsSummarized.find(
        ({ phaseId }) => phaseId === id,
      )?.id;

      if (!systemId) return;

      const system = systemsSelector.find((s) => s.id === systemId);

      if (system?.display !== display || system?.state !== state) {
        dispatch(
          extendedApi.util.updateQueryData(
            'getSystems',
            undefined,
            (systemsDraft) => {
              systemsDraft = systemsDraft?.map((s) => {
                if (
                  (s.display !== display || s.state !== state) &&
                  s.id === system.id
                ) {
                  return {
                    ...s,
                    display,
                    state,
                  };
                }
                return s;
              });
              return systemsDraft;
            },
          ),
        );
      }
    }
  }, [
    message,
    JSON.stringify(systems),
    JSON.stringify(systemsSelector),
    JSON.stringify(systemsSummarized),
  ]);

  const handleMessage = (newMessage: any) => {
    setMessage(newMessage);
  };

  const handlePhaseComponent = ({
    id,
    phaseId,
  }: {
    id: string;
    phaseId: string;
  }) => {
    setSystemsSummarized((prev) =>
      [
        ...new Set([...prev, { id, phaseId }].map((v) => JSON.stringify(v))),
      ].map((v) => JSON.parse(v)),
    );
  };

  const url = useMemo(() => {
    if (systemsSummarized.length === systems.length) {
      return `${apiBaseUrlV1(
        'stream/v2',
      )}/attributes/streams/json?${systemsSummarized
        .map(({ phaseId }) => `id=${phaseId}`)
        .join('&')}&rangeIsLive=true&samplerEnabled=false`;
    }
    return undefined;
  }, [systemsSummarized, systems]);

  const handleOpenedEvent = () => {
    if (!controlStatusLoaded) {
      dispatch(setControlSubscriptionStatusesLoaded(true));
    }
  };

  if (systems.length !== systemsSummarized.length && isSuccess)
    return (
      <>
        {systems.map((system) => (
          <WithSubscriptionItem
            key={system}
            system={system}
            handlePhaseComponent={handlePhaseComponent}
          />
        ))}
      </>
    );

  return (
    <PhaseEventSourceComponent
      handleStreamData={handleMessage}
      eventKeyType="/pc-domain/AttributeValueObject"
      urlProp={url}
      handleOpenedEvent={handleOpenedEvent}
    />
  );
};

// eslint-disable-next-line react/display-name
const withSubscription = (Component: any) => (props: any) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const match = matchPath(
    location.pathname,
    routes.map(({ path }) => path).reverse(),
  );

  const isEdgeEnv = useAppSelector(
    (state: IAppState) => state.environment === 'edge',
  );

  const [isSkip, setIsSkip] = useState<boolean>(true);

  useEffect(() => {
    if (
      routes.find(({ path }) => path.indexOf(match.path) > -1)
        ?.componentParent === 'PrivateRoute' &&
      isSkip
    ) {
      setIsSkip(false);
    }
  }, [match, isSkip]);

  const { data: systems = [] } = useGetSystemsQuery(undefined, {
    skip: isSkip,
  });

  const [systemsSubscribe, setSystemsSubscribe] = useState<string[]>([]);

  const { data: systemsStatus = [], error: pollingError } =
    useGetSystemStatusQuery(undefined, {
      pollingInterval: 10000,
      skip: isEdgeEnv || isSkip || systems.length === 0,
    });

  useEffect(() => {
    if (systemsStatus.length > 0 && !isEdgeEnv) {
      setSystemsSubscribe(
        systems
          ?.filter(({ id }) =>
            systemsStatus?.find(({ systemId }) => systemId === id),
          )
          ?.map(({ id }) => id),
      );
      dispatch(
        extendedApi.util.updateQueryData(
          'getSystems',
          undefined,
          (systemsDraft) => {
            const newData = systemsDraft.map((s) => {
              if (
                !systemsStatus.find(({ systemId }) => systemId === s.id) ||
                systemsStatus.find(({ systemId }) => systemId === s.id)
                  ?.online === false
              ) {
                return {
                  ...s,
                  state: undefined,
                  machineState: 'Offline',
                  display: undefined,
                };
              }
              return s;
            });
            return newData;
          },
        ),
      );
    }
    if (systems.length > 0 && isEdgeEnv) {
      setSystemsSubscribe(systems.map(({ id }) => id));
    }
  }, [JSON.stringify(systemsStatus), JSON.stringify(systems)]);

  useEffect(() => {
    if (isUnauthorizedError(pollingError)) {
      dispatch(setUser(undefined));
    }
  }, [pollingError]);

  return (
    <>
      {!pollingError && (
        <>
          {systems
            .filter(({ id }) =>
              systemsStatus.some(
                (systemStatus) => systemStatus.systemId === id,
              ),
            )
            .map((system) => (
              <MachineSubscriptionComponent key={system.id} system={system} />
            ))}
          {systemsSubscribe.length > 0 && (
            <WithSubscriptionSummarizedComponent systems={systemsSubscribe} />
          )}
        </>
      )}
      <Component {...props} />
    </>
  );
};

export default withSubscription;
